import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7521d2a6")
const _hoisted_1 = { class: "answer-game-view" }
const _hoisted_2 = { class: "game-loading" }
const _hoisted_3 = ["open"]
_popScopeId()

import { dataEmbeddingPoint } from "@/services";
import { GameMessageData, GameMessageSuccess, setWindowEnv } from "@/utils";
import { onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import config from "@/config/app.config";
import servers from "@/config/servers";
import { eventCenter, event } from "@evideo/frontend-utils";
import { loadMicroApp } from "qiankun";
import { setMicroAppMap, deleteMicroAppMap } from "@/main";
import moment from "moment";
import { onBeforeRouteLeave } from "vue-router";
import { Modal } from "ant-design-vue";


export default _defineComponent({
  setup(__props) {

const isTrue = ref<boolean>(false);
const confirmModal = ref<boolean>(false);
const NAME_SPACE = "answer-game";
const studentModalVisible = ref<boolean>(false); // 弹窗显示
const modal = ref<any>(null);
const store = useStore();
const gameApp = ref<any>();
const answerGameContainer = ref<any>();
const gameLoading = ref<boolean>(true);
const toolsAppRootRef = ref();
let h5ToolsApp: any;
let h5ToolsAppStatus = ref(0); // 0 关闭 1 打开
const gameState = ref();
const burialPointConfig = {
  uid: store.state.pcUserIdAES,
  ct: "96",
  ci: store.state.mac,
  mac: store.state.mac,
  sv: store.state.version,
  fv: store.state.hardwareModel,
  pt: "pc",
};

const gameConfig = {
  token: store.state.token,
  theme: "star",
  burialPointConfig,
};
const uploadPlayGame = () => {
  const config = {
    ...burialPointConfig,
    eventid: "teaching_answerGame_startGame",
    pageid: "answerGame",
    moduleid: "teaching",
    ca: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    eventattr: { name: "answerGame" },
  };
  const body = encodeURIComponent(JSON.stringify(config));
  dataEmbeddingPoint(body);
};
const handleSendGameConfig = () => {
  eventCenter.send(
    event.CONFIG_PACE_PRACTICE_GAME_INFO || `${NAME_SPACE}:app-config`,
    GameMessageSuccess(gameConfig)
  );
};

const handleGameLuanchOk = () => {
  handleSendGameConfig();
};

const loadGame = async () => {
  const env = JSON.stringify(process.env.RELEASE_ENV);
  let entryUrl = `${config[servers.musicGame]}/answer-game`;
  if (env === `"devp"` && localStorage.getItem("gameUrl") !== null) {
    entryUrl = localStorage.gameUrl;
  }
  // 兼容子应用hash路由模式
  // window.location.hash = "#/piano";

  eventCenter.sub(`${NAME_SPACE}:luanch-ok`, (data: GameMessageData) => {
    if (data.errorCode === 0) {
      handleGameLuanchOk();
    }
  });
  eventCenter.sub(`${NAME_SPACE}:load-ok`, (data: GameMessageData) => {
    if (data.errorCode === 0) {
      setTimeout(() => {
        gameLoading.value = false;
      }, 500);
    }
  });
  gameApp.value = await loadMicroApp(
    {
      name: "answerGamePC",
      entry: entryUrl,
      container: "#game-view-container",
      props: {
        args: {
          token: store.state.token,
          appName: "answerGamePC",
          runtime: {},
        },
      },
      micrAppUrl: entryUrl,
    },
    {
      sandbox: { experimentalStyleIsolation: true },
    }
  );
  setMicroAppMap("answer-game", gameApp.value);
};
const defaultStudent = {
  num: 2,
  token: localStorage.getItem("token"),
  classId: null,
  defaultStudentIds: [],
  disableClose: false,
  disableRandom: false,
};
/**
 * 启动学生弹窗工具
 */
const startSelectStudentComponent = (selectInfo?: any) => {
  if (h5ToolsAppStatus.value === 1) {
    eventCenter.send("select-student-close");
  } else {
    studentModalVisible.value = true;
    eventCenter.send("select-student-open", {
      ...defaultStudent,
      ...selectInfo,
      classId: store.state.className,
    });
  }
  h5ToolsAppStatus.value = h5ToolsAppStatus.value === 1 ? 0 : 1;
};
const initSelectStudentComponent = () => {
  eventCenter.send("load-select-student-component", {
    teacherToken: localStorage.getItem("token"),
  });
  eventCenter.sub("select-student-modal-close-complete", () => {
    h5ToolsAppStatus.value = 0;
    studentModalVisible.value = false;
    eventCenter.send(`${NAME_SPACE}:select-student-modal-close-complete`);
  });
  eventCenter.sub("select-student-modal-select", ({ selectedList }) => {
    eventCenter.send(`${NAME_SPACE}:selected-student`, selectedList);
  });
  eventCenter.sub("to-home-start-student-modal-select", () => {
    console.log("启动学生选择弹窗");
    startSelectStudentComponent();
  });
};

// 初始化学生弹窗
const initSelectStudentModal = async () => {
  const env = JSON.stringify(process.env.RELEASE_ENV);
  let entryUrl: any = `${config[servers.tools]}/`;
  if (env === `"devp"` && localStorage.getItem("toolsAppUrl") !== null) {
    entryUrl = localStorage.getItem("toolsAppUrl");
  }
  try {
    setTimeout(async () => {
      h5ToolsApp = await loadMicroApp(
        {
          name: "tools",
          entry: entryUrl,
          container: "#tools-app-root",
          props: {
            args: {
              token: store.state.token,
              appName: "tools",
              runtime: {},
            },
          },
        },
        {
          sandbox: { experimentalStyleIsolation: true },
        }
      );
    }, 1000);

    eventCenter.sub("h5-tools-init-complete", () => {
      initSelectStudentComponent();
      console.log("answerGame 启动学生弹窗项目成功");
    });
  } catch (e) {
    console.error("answerGame 启动学生弹窗项目失败:", e);
    console.error(e);
  }
};

onMounted(async () => {
  // 挂载平台标识，用于子系统判断自己所处的平台
  setWindowEnv();
  uploadPlayGame();
  await initSelectStudentModal();
  await loadGame();
  eventCenter.sub("game-state-updated", (newState: any) => {
    gameState.value = newState;
  });
  eventCenter.sub(
    `${NAME_SPACE}:select-student-open`,
    (data: GameMessageData) => {
      if (data.errorCode === 0) {
        if (!studentModalVisible.value) {
          startSelectStudentComponent({
            ...data.data,
          });
        }
      }
    }
  );
  eventCenter.sub(
    `${NAME_SPACE}:select-student-close`,
    (data: GameMessageData) => {
      if (data.errorCode === 0) {
        eventCenter.send("select-student-close");
      }
    }
  );
});
onBeforeRouteLeave((to, from, next) => {
  if (gameState.value !== 2) {
    next();
    return;
  }

  if (!isTrue.value) {
    confirmModal.value = true;
    const modalOptions = {
      content: "请确认是否中止游戏，退出答题PK？",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        if (modal.value) {
          modal.value.destroy();
        }
        modal.value = null;
        next();
        isTrue.value = !isTrue.value;
      },
      onCancel: () => {
        next(false);
        if (modal.value) {
          modal.value.destroy();
        }
        modal.value = null;
        confirmModal.value = false;
        history.pushState({ goToGameCenterFromAnswer: true }, "");
      },
      getContainer: ".answer-game-view",
    };
    modal.value
      ? modal.value.update(modalOptions)
      : (modal.value = Modal.confirm(modalOptions));
  }
});
onBeforeUnmount(async () => {
  if (gameApp.value) {
    if (gameApp.value.preUnmount) {
      await gameApp.value.preUnmount();
    }
    await gameApp.value.unmount();
    deleteMicroAppMap("answer-game");
  }
  if (h5ToolsApp) {
    await h5ToolsApp.unmount();
    await h5ToolsApp.unmountPromise;
    h5ToolsApp = undefined;
  }
});
onUnmounted(async () => {
  if (gameApp.value) {
    if (gameApp.value.preUnmount) {
      await gameApp.value.preUnmount();
    }
    await gameApp.value.unmount();
    deleteMicroAppMap("answer-game");
  }
  if (h5ToolsApp) {
    await h5ToolsApp.unmount();
    await h5ToolsApp.unmountPromise;
    h5ToolsApp = undefined;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, "游戏加载中,请稍后...", 512), [
      [_vShow, gameLoading.value]
    ]),
    _withDirectives(_createElementVNode("div", {
      id: "game-view-container",
      ref: answerGameContainer,
      class: "game-container",
      style: _normalizeStyle({ zIndex: confirmModal.value ? -1 : 0 })
    }, null, 4), [
      [_vShow, !gameLoading.value]
    ]),
    _createElementVNode("div", {
      open: _unref(h5ToolsAppStatus) === 1,
      id: "tools-app-root",
      ref: toolsAppRootRef,
      class: "tools-app-root transition"
    }, null, 8, _hoisted_3)
  ]))
}
}

})